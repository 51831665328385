/* This example requires Tailwind CSS v2.0+ */
import {Fragment, useEffect, useState} from 'react'
import {Dialog, Transition} from '@headlessui/react'

export default function Popup({isOpen, item}) {
    const [open, setOpen] = useState(false)
    const [display, setDisplay] = useState("")

    useEffect(() => {
        window.gtag("event", "view_gallery", item.title)
        setOpen(isOpen)
        if (item.youtube) {
            fetch("https://www.youtube.com/oembed?url=" + item.youtube).then(i => i.json()).then(d => {
                const htmlCode = d.html ? d.html : "<img src='" + item.url + "' />"
                setDisplay(htmlCode)

            })
        } else {
            setDisplay("<img src='" + item.url + "' />")
        }

    }, [isOpen, item])


    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="popup fixed z-11 inset-0 overflow-y-auto" onClose={setOpen}>
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div
                            className="popup-space inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle  sm:w-full sm:p-6">
                            <div>
                                <div className="mt-3 text-center sm:mt-5">
                                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                                        {item.title}
                                    </Dialog.Title>
                                    <div className="my-2 mx-auto">
                                        <div dangerouslySetInnerHTML={{__html: display}}/>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}