import {useEffect, useState} from "react";
import GalleryUI from "../components/GalleryUI.js";
// import {getGallery} from "../lib/api.jsx";

export default function Gallery() {
    const [galleryData, setGalleryData] = useState([])
    const [tag, setTag] = useState([])
    useEffect(() => {
        const g = [
        ]
        // loop  1 to 10
        for (let i = 1; i <= 10; i++) {
            g.push({
                url: `/images/${i}.webp`,
                title: `Image ${i}`,
                tag: `tag${i}`
            })
        }


        // getGallery().then(g => {
        let tag = []
        g.map(i => tag = [...tag, ...i.tag.split(",")])
        setGalleryData(g)
        setTag(Array.from(new Set(tag)))
        // })


    }, [])
    return <GalleryUI tag={tag} galleryData={galleryData} key={galleryData.length}></GalleryUI>
}