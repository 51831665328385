import {useEffect, useState} from "react"
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
// import {getProfile} from "../lib/api.jsx";


export default function Profile() {
    const [profile, setProfile] = useState({attributes: {}})
    useEffect(() => {
        // getProfile().then(p => {
        //     setProfile(p)
        // })
        const sampleProfile = {
            attributes: {
                intro: `# テキスト`,
            }
        }

        setProfile(sampleProfile)
    }, [])
    // console.log({profile})
    return (
        <>

            <div className={"page-container profile"}>
                <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                    {profile.attributes.intro}
                </ReactMarkdown>
            </div>
        </>
    )

}
