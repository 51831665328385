import {useState} from "react";
export default function ContactForm() {
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [message, setMessage] = useState("")
    const [copy, setCopy] = useState(false)

    const sendForm = () => {
        window.gtag("event", "sendForm")
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "text/plain");

        const raw = btoa(unescape(encodeURIComponent(JSON.stringify({
            name,
            email,
            message: message.replaceAll("\n", "<br/>"),
            copy
        }))))
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        const url = "https://script.google.com/macros/s/AKfycbxojkc7GPClzrEllgpIvDw3-k3UIslRPBtsBhtys8HmM2Kg4Ixk9y0w9AKtbmNOMXvy/exec"
        fetch(url, requestOptions)
            .then(response => response.text())
            .then(result => {
                window.alert("完成")
                setCopy(false)
                setName("")
                setEmail("")
                setMessage("")
            })
            .catch(error => console.log('error', error));
    }
    return (
        <div className="block p-6 rounded-lg  bg-white w-full">
            <form>
                <div className="form-group mb-6">
                    <input type="text" className="text-input" id="name"
                           onChange={(e) => setName(e.target.value)}
                           value={name}
                           placeholder="名前"/>
                </div>
                <div className="form-group mb-6">
                    <input type="email" className="text-input" id="email"
                           onChange={(e) => setEmail(e.target.value)}
                           value={email}
                           placeholder="メール"/>
                </div>
                <div className="form-group mb-6">
<textarea
    className=" form-control text-area "
    id="message"
    onChange={(e) => setMessage(e.target.value)}
    value={message}
    rows="3"
    placeholder="メッセージ"
></textarea>
                </div>
                <div className="form-group form-check text-center mb-6">
                    <input type="checkbox"
                           onChange={(e) => setCopy(e.target.checked)}
                           checked={copy}
                           className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-purple-600 checked:border-purple-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain mr-2 cursor-pointer"
                           id="exampleCheck87"/>
                    <label className="form-check-label inline-block text-gray-800" htmlFor="exampleCheck87">
                        このメッセージのコピーを送信する
                    </label>
                </div>
                <div onClick={() => sendForm()} className="
w-full
px-6
py-2.5
bg-purple-600
text-white
font-medium
text-xs
leading-tight
uppercase
rounded
shadow-md
hover:bg-purple-700 hover:shadow-lg
focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0
active:bg-purple-800 active:shadow-lg
transition
duration-150
ease-in-out">送信
                </div>
            </form>
        </div>
    )

}