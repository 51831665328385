import {Accordion, AccordionBody, AccordionHeader, AccordionItem} from "react-headless-accordion";

export default function ProductList({item}) {
    return (

        <Accordion className={"accordion"}>
            {
                item.map(i => (
                    <AccordionItem key={JSON.stringify(i)}>
                        <AccordionHeader>
                            <div className={`accordion-title`}>{i.attributes.name} ▼</div>
                        </AccordionHeader>
                        <AccordionBody className={"accordion-body"}>
                            {
                                i.attributes.options.map(o => (
                                    <div key={JSON.stringify(o)} className={"accordion-content"}>
                                        {o.text}
                                    </div>
                                ))
                            }
                        </AccordionBody>
                    </AccordionItem>

                ))
            }
        </Accordion>

    )
}