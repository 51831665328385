import IndexSlide from "../components/IndexSlide.js";
import {useEffect, useState} from "react";


export default  function Index() {
    const [slides, setSlides] = useState([])
    useEffect(() => {
       // getSlideShow().then(slide=>{
       // })
        setSlides([
            {src:"/images/1.webp"},
            {src:"/images/2.webp"},
            {src:"/images/3.webp"},
        ])

    }, [])
    const start = 2023
    const year = new Date().getFullYear() === start ? start : `${start} - ${new Date().getFullYear()}`

    return (
        <>
            <div className={""}>
                <IndexSlide
                    slides={slides}/>
            </div>
            <div className="copyright">
                Ⓒ {year} Layla Yuzduki <br/>
                サイト内全ての画像の <br/>
                無料転載、加工、無断使用は一切禁止です。 <br/>
                Unauthorized reproduction prohibited
            </div>
        </>

    )
}