"use client"
import {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faVideo} from '@fortawesome/free-solid-svg-icons'
import Popup from "./Popup.js"
import LazyImage from "./LazyImage.js"
export default function GalleryUI({tag, galleryData}) {
    const [gallery, setGallery] = useState(galleryData)
    const [selected, setSelect] = useState("reset")
    const [showPop, setShowPop] = useState(false)
    const [key, setKey] = useState("hello")
    const [pop, setPop] = useState({})
    const displayPreview = (d) => {
        setPop(d)
        setKey(String(Math.random()))
        setShowPop(true)
    }
    const filterData = (type) => {
        setSelect(type)
        if (type === "reset") {
            setGallery(galleryData)
            return true
        }
        const filtered = galleryData.filter(i => {
            const tags = i.tag ?? ""
            return tags.split(",").includes(type)
        })
        setGallery(filtered)
    }

    return (
        <>
            <div className={"page-container gallery"}>
                <Popup isOpen={showPop} item={pop} key={key}></Popup>
                <div>
                    {
                        tag.map(i => {
                            return (<div key={i} className={`${selected === i ? "active" : ""} btn inline-block my-1 mr-2`}
                                         onClick={() => filterData(i)}>{i}</div>)
                        })
                    }
                    <div>
                        <div className={"btn inline-block my-2"} onClick={() => filterData("reset")}>リセット</div>
                    </div>


                </div>

                <div className="columns-1 md:columns-2 xl:columns-3 gap-8 space-y-8">
                    {/*<img className="w-full aspect-video ..." src="..."/>*/}
                    {gallery.map(i => (
                        <div className={"relative gallery-item cursor-pointer shadow-lg"} onClick={() => displayPreview(i)}
                             key={i.url}>

                            <LazyImage className={`w-full object-cover rounded-lg`} alt={i.title}
                                       src={i.url}></LazyImage>
                            <span className={i.youtube ? "video" : ""}>
                                  {
                                      i.youtube ?
                                          <FontAwesomeIcon className={"mr-2"} icon={faVideo} key={i.youtube}/> : <></>
                                  }
                                {i.title}</span>
                        </div>
                    ))
                    }
                </div>

            </div>
        </>
    )

}
