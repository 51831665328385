import "./styles/globals.scss"
import {Outlet} from "react-router-dom";
import Header from "./components/Header.js";
import {Helmet} from "react-helmet";
import {useEffect} from "react";

export default function Root(children) {

    useEffect(() => {
        document.addEventListener('contextmenu', (e) => {
            e.preventDefault();
        });

    }, [])

    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
            </Helmet>
            <Header></Header>
            <div id="main">
                <Outlet></Outlet>
            </div>
        </>
    );
}