import React, {useEffect} from "react";


const IndexSlide = ({slides}) => {
    useEffect(() => {
        // console.log("Run", {slides})
        if(slides.length===0){
            return
        }
        if (window) {
            window.jQuery = require('jquery');
            window.vegas = require('vegas');

            window.jQuery('#body-vegas').vegas({
                overlay: '/04.png',
                timer: false,
                transition: ['swirlRight2','swirlLeft2'],
                transitionDuration: 10000,
                delay: 5000,
                animation: ['kenburnsLeft'],
                animationDuration: 10000,
                slides:slides

            });
            return (() => {
                window.jQuery('#body-vegas').vegas('destroy');
            })
        }

    }, [slides]);
    return (
        <>
            <div id="body-vegas">
            </div>
        </>
    )

}

export default IndexSlide