import { createHashRouter, redirect} from "react-router-dom";
import Root from "./Root.js";
import Index from "./pages/index.js";
import Profile from "./pages/profile.js";
import Gallery from "./pages/gallery.js";
import Contact from "./pages/contact.js";

const returnError = async () =>{
    return redirect("/error");
}

const router = createHashRouter([
    {
        path: "/",
        element: <Root/>,
        // errorElement: <Root />,
        children: [
            {
                path: "/",
                element: <Index/>,
            },
            {
                path: "gallery",
                element: <Gallery/>,
            },
            {
                path: "profile",
                element: <Profile/>,
            },
            {
                path: "contact",
                element: <Contact/>,
            },
            {
                path: "error",
                element: <div>Error</div>,
            },
            {
                path: "*",
                element:<div></div>,
                loader: returnError
            },
        ],

    },

]);

export default router