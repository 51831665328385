import React, {Fragment} from "react";
import ReactPlaceholder from "react-placeholder";

class LazyImage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props,
            loaded: false
        }
    }

    handleImageLoaded() {
        this.setState({loaded: true});
    }

    render() {
        const {loaded} = this.state;
        const imageStyle = !loaded ? {display: "none"} : {};
        const image = <img src={this.state.src} alt={this.state.title} style={imageStyle}
                           onLoad={this.handleImageLoaded.bind(this)}/>;
        return (
            <Fragment>
                <ReactPlaceholder type="rect" ready={this.state.loaded} style={{height: 160}}>
                    <div className="imageHolder">
                        {image}
                    </div>
                </ReactPlaceholder>
                {!loaded && image}
            </Fragment>
        )
    }
}

export default LazyImage