import ContactForm from "../components/ContactForm.js";
import {useEffect, useState} from "react";
// import {getProducts} from "../lib/api.jsx";
import ProductList from "../components/ProductList.js";


export default function Contact() {
    const [item, setItem] = useState([])
    // useEffect(()=>{
    //     getProducts().then((d)=>setItem(d))
    // },[])
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const sampleItem = [
        {
            attributes: {
                name: "サンプル",
                options: [
                    {text: "サンプル1 - 100円"},
                    {text: "サンプル2 - 200円"},
                    {text: "サンプル3 - 300円"},

                ],
            },
        },
        {
            attributes: {
                name: "サンプル 2 ",
                options: [
                    {text: "サンプル1 - 100円"},
                    {text: "サンプル2 - 200円"},
                    {text: "サンプル3 - 300円"},

                ],
            },
        }
    ]
    useEffect(() => {
        setItem(sampleItem)
    }, [sampleItem])

    return (
        <div className={"page-container"}>
            <h1 className={"text-2xl"}>
                料金表
            </h1>
            <hr/>
            <br/>
            <ProductList item={item}/>
            <br/>

            <h1 className={"text-2xl"}>
                連絡フォーム
            </h1>
            <hr/>
            <br/>
            <ContactForm></ContactForm>
        </div>
    )

}
