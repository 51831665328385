"use client"
/* This example requires Tailwind CSS v2.0+ */
import {Fragment, useEffect, useState} from 'react'
import {useLocation} from 'react-router-dom'

export default function Header() {
    const [open, setOpen] = useState(false)
    const [flag, setFlag] = useState(false)
    const [display, setDisplay] = useState("")
    const location = useLocation();
    const currentPath = location.pathname

    const menu = [
        {
            text: "Gallery",
            path: "/#/gallery"
        },
        {
            text: "Profile",
            path: "/#/profile"
        }
        ,
        {
            text: "Contact",
            path: "/#/contact"
        }
    ]

    useEffect(() => {
        // console.log({currentPath})
        window.gtag("event", "pageView", currentPath)
        setOpen(false)
        setDisplay("")
        setTimeout(() => setFlag(false))
        setTimeout(() => setDisplay(currentPath), 500)
        setTimeout(() => setFlag(true), 500)
    }, [currentPath])

    // const genericHamburgerLine = `h-1 w-6 my-1 rounded-full bg-black transition ease transform duration-300`;

    return (

        <>
            <div className={"nav-container "}>
                <div className={"hidden md:block"}>

                    {menu.map((object, i) =>
                        <a href={object.path} className={"menu-anchor"} key={`menu-${i}`}>
                            <div
                                className={`nav-item ${object.path.includes(currentPath) && currentPath!=="/" ? "active" : ""}`}>
                                {object.text}

                            </div>
                        </a>
                    )}
                </div>

            </div>

            <a href="/" className={"hidden md:block"}>
                <div className="big-icon"></div>
            </a>
            <div className={`block md:hidden mobile-menu-icon ${open ? "open" : ""}`} onClick={() => setOpen(!open)}>
                <div className="big-icon"></div>
            </div>
            {(currentPath.length > 1) ? (<div className={`icon-nav ${flag ? "open" : ""}`}>
                {display.replace("/", "")}
            </div>) : <></>}


            <div className="md:hidden">
                <div className={`bg-gray-900 mobile-menu ${open ? "open" : ""}`}>
                    <div className="list">
                        <a href="/">
                            <div
                                className={`nav-item ${currentPath === "/" ? "active" : ""}`}>
                                HOME
                            </div>
                        </a>
                        {menu.map((object, i) =>
                            <a href={object.path} key={`menu-${i}`}>
                                <div
                                    className={`nav-item ${object.path.includes(currentPath) && currentPath!=="/" ? "active" : ""}`}>{object.text}
                                </div>
                            </a>
                        )}
                    </div>
                </div>


            </div>
        </>

    )
}